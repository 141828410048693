import * as React from 'react';
import { Helmet } from 'react-helmet';

import ap57 from '../../../assets/favicon/apple-icon-57x57.png';
import ap60 from '../../../assets/favicon/apple-icon-60x60.png';
import ap72 from '../../../assets/favicon/apple-icon-72x72.png';
import ap76 from '../../../assets/favicon/apple-icon-76x76.png';
import ap114 from '../../../assets/favicon/apple-icon-114x114.png';
import ap120 from '../../../assets/favicon/apple-icon-120x120.png';
import ap144 from '../../../assets/favicon/apple-icon-144x144.png';
import ap152 from '../../../assets/favicon/apple-icon-152x152.png';
import ap180 from '../../../assets/favicon/apple-icon-180x180.png';

import ad144 from '../../../assets/favicon/android-icon-144x144.png';
import ad192 from '../../../assets/favicon/android-icon-192x192.png';

import f16 from '../../../assets/favicon/favicon-16x16.png';
import f32 from '../../../assets/favicon/favicon-32x32.png';
import f96 from '../../../assets/favicon/favicon-96x96.png';

import ms144 from '../../../assets/favicon/ms-icon-144x144.png';

export interface HeadProps {
  title?: string;
}

const Head: React.FunctionComponent<HeadProps> = (props) => {
  return (
    <Helmet title={props.title ? props.title : 'Opinous'}>
      <link
        rel='stylesheet'
        href='https://pro.fontawesome.com/releases/v5.7.2/css/all.css'
        integrity='sha384-6jHF7Z3XI3fF4XZixAuSu0gGKrXwoX/w3uFPxC56OtjChio7wtTGJWRW53Nhx6Ev'
        crossOrigin='anonymous'
      />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <link rel='apple-touch-icon' sizes='57x57' href={ap57} />
      <link rel='apple-touch-icon' sizes='60x60' href={ap60} />
      <link rel='apple-touch-icon' sizes='72x72' href={ap72} />
      <link rel='apple-touch-icon' sizes='76x76' href={ap76} />
      <link rel='apple-touch-icon' sizes='114x114' href={ap114} />
      <link rel='apple-touch-icon' sizes='120x120' href={ap120} />
      <link rel='apple-touch-icon' sizes='144x144' href={ap144} />
      <link rel='apple-touch-icon' sizes='152x152' href={ap152} />
      <link rel='apple-touch-icon' sizes='180x180' href={ap180} />
      <link rel='icon' type='image/png' sizes='144x144' href={ad144} />
      <link rel='icon' type='image/png' sizes='192x192' href={ad192} />
      <link rel='icon' type='image/png' sizes='16x16' href={f16} />
      <link rel='icon' type='image/png' sizes='32x32' href={f32} />
      <link rel='icon' type='image/png' sizes='96x96' href={f96} />
      <meta name='msapplication-TileColor' content='#6b5aed' />
      <meta name='msapplication-TileImage' content={ms144} />
      <meta name='theme-color' content='#6b5aed' />
    </Helmet>
  );
};

export default Head;
